import * as React from "react"

import Seo from "../components/seo"

const StudentVoicesPage = () => {
  return (
    <section id="student-voices">
      <Seo
        title="Student Voices"
        description="Structure school to be useful, convenient and supportive for the students it's meant to serve. Here are student stories in their own words."
      />
      <div className="one smaller-center-text">
        <div className="container">
          <div className="content">
            <h2 className="title-full">Student Voices</h2>
            <p>
              When we structure school to be the most useful, convenient and
              supportive to the students its meant to serve, we help more of
              them be successful. Here are just some of their voices.
            </p>
          </div>
        </div>
      </div>
      <div className="two split-half-circle-right">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="wrap-content">
                {/* <h2 className="title-full">Student Voices</h2> */}
                <p>
                  “I have been able to pursue a lot of jobs and build a way
                  better relationship with my family. I’m very grateful to be
                  able to have the freedom to be my own person in my education.”{" "}
                  <br />- Abigal S., age 16
                </p>
              </div>
            </div>
            <div className="right">
              {/* <h2 className="title-mobile">Student Voices</h2> */}
              <div className="circle-pic"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="three split-half-circle-left">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="circle-pic"></div>
            </div>
            <div className="right">
              <div className="wrap-content">
                <p>
                  “I dropped out of school because I never got the help I
                  needed. Without motivation you will never see progress or
                  change. Now I’m in a school that cares about my situation,
                  accommodates my schedule and makes sure I’m always on task and
                  not left behind.” <br />- Alonda N, age 16
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="four split-half-circle-right">
        <div className="container">
          <div className="content">
            <div className="left">
              <div className="wrap-content">
                <p>
                  “I’ve always been stressed when it comes to a normal school
                  environment. Kids would bully me constantly and it got to the
                  point where I was extremely depressed and almost flunked 8th
                  grade. Then I found a school with flexibility, a safe
                  environment and one on one attention. I’m doing so much better
                  now!”
                  <br /> - Echo C., age 14
                </p>
              </div>
            </div>
            <div className="right">
              <div className="circle-pic"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StudentVoicesPage
